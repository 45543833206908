export const FETCH_GEO_LOCATION = 'FETCH_GEO_LOCATION';
export const CLEAR_GEO_LOCATION = 'CLEAR_GEO_LOCATION';
export const GET_TAX = 'GET_TAX';
export const FETCH_GEO_LOCATION_SUCCESS = 'FETCH_GEO_LOCATION_SUCCESS';
export const FETCH_GEO_LOCATION_ERROR = 'FETCH_GEO_LOCATION_ERROR';
export const FETCH_TASK_GEO_LOCATION = 'FETCH_TASK_GEO_LOCATION';
export const FETCH_TASK_GEO_LOCATION_SUCCESS =
  'FETCH_TASK_GEO_LOCATION_SUCCESS';
export const FETCH_TASK_GEO_LOCATION_ERROR = 'FETCH_TASK_GEO_LOCATION_ERROR';
export const FETCH_CITY_GEO_LOCATION_SUCCESS =
  'FETCH_CITY_GEO_LOCATION_SUCCESS';
export const FETCH_CITY_GEO_LOCATION_ERROR = 'FETCH_CITY_GEO_LOCATION_ERROR';
export const FETCH_PARTNER_ADDONS_PER_LOCATION_SUCCESS =
  'FETCH_PARTNER_ADDONS_PER_LOCATION_SUCCESS';
export const FETCH_PARTNER_ADDONS_PER_LOCATION_ERROR =
  'FETCH_PARTNER_ADDONS_PER_LOCATION_ERROR';
