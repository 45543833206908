import produce from "immer";
import * as discountConstants from '../constants/adminDiscount';

const initialState = {
    isLoading: false,
    isSaved: false,
    isWaiting: false,
    total: 0,
    discounts: [],
    error: null,
    errorSave: null,
    errorUpdate: null,
    errorDelete: null,
    deleteSuccess: null,
    viewedDiscount: null
}

const adminDiscountReducer = (state = initialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case discountConstants.GET_DISCOUNTS:
                draft.isSaved = false;
                draft.isLoading = true;
                draft.total = 0;
                draft.discounts = [];
                draft.error = null;
                draft.deleteSuccess = false;
                break;
            case discountConstants.GET_DISCOUNTS_SUCCESS:
                draft.isLoading = false;
                draft.total = action.total;
                draft.discounts = action.discounts;
                break;
            case discountConstants.GET_DISCOUNTS_ERROR:
                draft.isLoading = false;
                draft.total = 0;
                draft.discounts = [];
                draft.error = action.payload;
                break;

            case discountConstants.CREATE_DISCOUNT:
                draft.isSaved = false;
                draft.isWaiting = true;
                draft.error = null;
                draft.errorSave = null;
                draft.deleteSuccess = false;
                break;
            case discountConstants.CREATE_DISCOUNT_SUCCESS:
                draft.isSaved = true;
                draft.isWaiting = false;
                draft.viewedDiscount = action.discount;
                break;
            case discountConstants.CREATE_DISCOUNT_ERROR:
                draft.isSaved = false;
                draft.isWaiting = false;
                draft.errorSave = action.payload;
                break;

            case discountConstants.GET_DISCOUNT_BY_ID:
                draft.isSaved = false;
                draft.isLoading = true;
                draft.isSaved = false;
                draft.error = null;
                draft.deleteSuccess = false;
                break;
            case discountConstants.GET_DISCOUNT_BY_ID_SUCCESS:
                draft.isLoading = false;
                draft.viewedDiscount = action.discount;
                draft.error = null;
                break;
            case discountConstants.GET_DISCOUNT_BY_ID_ERROR:
                draft.isLoading = false;
                draft.error = action.payload;
                break;

            case discountConstants.UPDATE_DISCOUNT:
                draft.isSaved = false;
                draft.isWaiting = true;
                draft.error = null;
                draft.errorUpdate = null;
                draft.deleteSuccess = false;
                break;
            case discountConstants.UPDATE_DISCOUNT_SUCCESS:
                draft.isSaved = true;
                draft.isWaiting = false;
                draft.viewedDiscount = action.discount;
                break;
            case discountConstants.UPDATE_DISCOUNT_ERROR:
                draft.isSaved = false;
                draft.isWaiting = false;
                draft.errorUpdate = action.payload;
                break;

            case discountConstants.DELETE_DISCOUNT:
                draft.isSaved = false;
                draft.isWaiting = false;
                draft.error = null;
                draft.errorDelete = null;
                draft.deleteSuccess = false;
                break;
            case discountConstants.DELETE_DISCOUNT_SUCCESS:
                draft.isSaved = true;
                draft.isWaiting = false;
                draft.deleteSuccess = true;
                break;
            case discountConstants.DELETE_DISCOUNT_ERROR:
                draft.isSaved = false;
                draft.isWaiting = false;
                draft.errorDelete = action.payload;
                draft.deleteSuccess = false;
                break;

            // FIXME currently not in use yet
            case discountConstants.RESET_ERROR:
                draft.error = null;
                draft.errorSave = null;
                draft.errorUpdate = null;
                draft.errorDelete = null;
                draft.deleteSuccess = false;
                break;
            default:
                return state;
        }
    });

export { adminDiscountReducer, initialState };