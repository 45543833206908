import produce from 'immer';
import moment from 'moment-timezone';

import {
  FETCH_GEO_LOCATION,
  CLEAR_GEO_LOCATION,
  GET_TAX,
  FETCH_GEO_LOCATION_SUCCESS,
  FETCH_GEO_LOCATION_ERROR,
  FETCH_TASK_GEO_LOCATION,
  FETCH_TASK_GEO_LOCATION_SUCCESS,
  FETCH_TASK_GEO_LOCATION_ERROR,
  FETCH_CITY_GEO_LOCATION_SUCCESS,
  FETCH_PARTNER_ADDONS_PER_LOCATION_SUCCESS,
  FETCH_CITY_GEO_LOCATION_ERROR,
  FETCH_PARTNER_ADDONS_PER_LOCATION_ERROR,
} from '../constants/geo';

/** Initial state refers to default Montreal settings */
const initialState = {
  province: 'QC',
  timezone: moment.tz.guess(true) || 'America/Toronto',
  proximity: [-73.646116, 45.498647],
  pickup: {},
  dropoff: {},
  partners: [],
  tax: {
    type: 'gst+qst',
    rate: 0.14975,
  },
  isLoading: false,
  error: null,
  stillLoading: false,
  locations: null,
};

const geoReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case FETCH_GEO_LOCATION:
        draft.isLoading = true;
        draft.error = null;
        break;
      case FETCH_GEO_LOCATION_SUCCESS:
        draft.isLoading = false;
        Object.entries(action.res).forEach(([key, val]) => {
          draft[key] = val;
        });
        break;
      case FETCH_CITY_GEO_LOCATION_SUCCESS:
        draft[action.direction] = action.payload;
        draft.cityError = false;
        break;
      case FETCH_CITY_GEO_LOCATION_ERROR:
        draft.cityError = action.payload;
        break;
      case FETCH_PARTNER_ADDONS_PER_LOCATION_SUCCESS:
        draft.partners = action.payload;
        break;
      case FETCH_PARTNER_ADDONS_PER_LOCATION_ERROR:
        draft.partnersError = action.payload;
        break;
      case FETCH_GEO_LOCATION_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      case FETCH_TASK_GEO_LOCATION:
        draft.stillLoading = true;
        draft.error = null;
        break;
      case FETCH_TASK_GEO_LOCATION_SUCCESS:
        draft.stillLoading = false;
        draft.locations = action.data;
        break;
      case FETCH_TASK_GEO_LOCATION_ERROR:
        draft.stillLoading = false;
        draft.error = action.error;
        break;
      case GET_TAX:
        draft.tax = action.tax;
        break;
      case CLEAR_GEO_LOCATION:
        state = initialState;
        return state;
      default:
        return state;
    }
  });
};

export { geoReducer, initialState };
