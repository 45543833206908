import produce from 'immer';
import * as taskConstants from '../constants/adminTask';

const initialState = {
  isLoading: false,
  total: 0,
  tasks: [],
  onfleetTasks: [],
  contacts: [],
  error: null,
  viewedTask: null,
  isSending: false,
  isSent: false,
  messageStatus: {}
};

const adminTaskReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case taskConstants.GET_TASKS:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case taskConstants.GET_TASKS_SUCCESS:
        draft.isLoading = false;
        draft.tasks = action.tasks;
        draft.total = action.total;
        break;
      case taskConstants.GET_TASKS_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case taskConstants.GET_TASK_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        break;
      case taskConstants.GET_TASK_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedTask = action.task;
        break;
      case taskConstants.GET_TASK_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case taskConstants.GET_ONFLEET_TASKS:
        draft.isSending = false
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case taskConstants.GET_ONFLEET_TASKS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          onfleetTasks: action.tasks
        }
      case taskConstants.GET_ONFLEET_TASKS_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case taskConstants.GET_ARRIVAL_TASKS:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case taskConstants.GET_ARRIVAL_TASKS_SUCCESS:
        draft.isLoading = false;
        draft.onfleetTasks = action.tasks;
        break;
      case taskConstants.GET_ARRIVAL_TASKS_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case taskConstants.CHANGE_ONFLEET_TASK_SIZE:
        // eslint-disable-next-line no-case-declarations
        const index = draft.onfleetTasks.findIndex(task => task.shortId === action.payload.taskId)
        if (index !== -1) {
          draft.onfleetTasks[index].size = action.payload.size
        }
        break
      case taskConstants.CHANGE_ONFLEET_TASK_TIME_RANGE:
        // eslint-disable-next-line no-case-declarations
        const taskIndex = draft.onfleetTasks.findIndex(task => task.shortId === action.payload.taskId)
        if (taskIndex !== -1) {
          const newDateTimeRange = draft.onfleetTasks[taskIndex].completeAfter.split(' ')[0] + ' ' + action.payload.time
          draft.onfleetTasks[taskIndex].completeAfter = newDateTimeRange
        }
        break
      case taskConstants.SEND_SMS_TASK:
        draft.isSending = true
        break;
      case taskConstants.SEND_SMS_TASK_ERROR:
        draft.isSending = false // action.payload
        break;
        case taskConstants.GET_CONTACTS:
          draft.contacts = action.payload;
          draft.isWaiting = true;
          draft.error = null
        // eslint-disable-next-line no-fallthrough
        case taskConstants.GET_CONTACTS_SUCCESS:
          draft.contacts = action.payload;
          draft.isSaved = true;
          draft.isWaiting = false;
          break;
        case taskConstants.GET_CONTACTS_ERROR:
          draft.isSaved = false;
          draft.isWaiting = false;
          draft.errorSave = action.payload;
          break;
        case taskConstants.SEND_CUSTOM_SMS:
          draft.isSending = true;
          draft.messageStatus = action.payload;
          break;
        case taskConstants.SEND_CUSTOM_SMS_ERROR:
          draft.isSending = false;
          break;
      default:
        return state;
    }
  });

export { adminTaskReducer, initialState };
