import produce from 'immer';
import {
    GET_PARTNER_INVOICE,
    GET_PARTNER_INVOICE_SUCCESS,
    GET_PARTNER_INVOICE_ERROR,
} from '../constants/partnerInvoices';

const initialState =    {
    invoices: null,
    error: null,
    isLoading: false,
};

const partnerInvoiceReducer = (state = initialState, action)    =>
    produce(state, (draft) =>    {
        switch (action.type)    {
            case GET_PARTNER_INVOICE:
                draft.isLoading = true;
                draft.invoices = null;
                draft.error = null;
                break;
            case GET_PARTNER_INVOICE_SUCCESS:
                draft.invoices = action.payload;
                draft.error = null;
                draft.isLoading = false;
                break;
            case GET_PARTNER_INVOICE_ERROR:
                draft.invoices = action.payload;
                draft.error = action.error;
                draft.isLoading = false
        }
    });

export { partnerInvoiceReducer, initialState };


