import produce from 'immer';
import { CHANGE_LOCALE, OPEN_LOCALE_MENU } from '../constants/language';

const getBrowserLanguage = () => {
  const { navigator } = window;
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.userLanguage ||
    'en'
  );
};

const chooseDefaultLanguage = () => {
  const browserLanguage = getBrowserLanguage();
  if (browserLanguage.indexOf('fr') >= 0) {
    return 'fr';
  }
  return 'en';
};

const initialState = {
  locale: chooseDefaultLanguage() || 'en',
  menu: false,
};

const languageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale;
        break;
      case OPEN_LOCALE_MENU:
        draft.menu = action.menu;
        break;
      default:
        return state;
    }
  });

export { languageReducer, initialState };
