import produce from 'immer';
import * as rateConstants from '../constants/adminRate';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  total: 0,
  rates: [],
  error: null,
  errorSave: null,
  viewedRate: null,
};

const adminRateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case rateConstants.CREATE_RATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case rateConstants.CREATE_RATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case rateConstants.CREATE_RATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case rateConstants.GET_RATE_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case rateConstants.GET_RATE_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedRate = action.rate;
        break;
      case rateConstants.GET_RATE_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case rateConstants.GET_RATES:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case rateConstants.GET_RATES_SUCCESS:
        draft.isLoading = false;
        draft.rates = action.rates;
        draft.total = action.total;
        break;
      case rateConstants.GET_RATES_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case rateConstants.DELETE_RATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case rateConstants.DELETE_RATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case rateConstants.DELETE_RATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case rateConstants.UPDATE_RATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case rateConstants.UPDATE_RATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case rateConstants.UPDATE_RATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      // FIXME currently not in use yet
      case rateConstants.RESET_ERROR:
        draft.error = null;
        break;

      default:
        return state;
    }
  });

export { adminRateReducer, initialState };
