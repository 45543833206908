import produce from 'immer';
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  SET_LOADING,
  CLEAR_USER_INFO,
  CLEAR_USER_ERROR,
  UPDATE_USER_NOTIFIED,
} from '../constants/user';

const initialState = {
  billingAddress: {
    unit: '',
    streetName: '',
    aptUnit: '',
    city: '',
    postalCode: '',
    province: '',
    country: '',
  },
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  survey: null,
  surveyOther: null,

  loading: true,
  userError: null,
  popupAbandonedBookingNotified: false,
};

const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_USER_PROFILE:
        draft.userError = null;
        Object.entries(action.profileData).forEach(([key, value]) => {
          draft[key] = value;
        });
        break;
      case UPDATE_USER_PROFILE_ERROR:
        draft.userError = action.payload;
        break;
      case UPDATE_USER_NOTIFIED:
        draft.popupAbandonedBookingNotified = true;
        break;

      case GET_USER_PROFILE:
        draft.userError = null;
        draft.billingAddress = action.billingAddress || {
          unit: null,
          streetName: null,
          aptUnit: '',
          city: null,
          postalCode: null,
          province: null,
          country: null,
        };
        draft.firstName = action.firstName;
        draft.lastName = action.lastName;
        draft.phone = action.phone;
        draft.email = action.email;
        draft.survey = action.survey;
        draft.surveyOther = action.surveyOther;
        draft.userError = null;
        break;
      case GET_USER_PROFILE_ERROR:
        draft.userError = action.payload;
        break;
      case SET_LOADING:
        draft.loading = action.loading;
        break;

      case CLEAR_USER_ERROR:
        draft.userError = null;
        break;
      case CLEAR_USER_INFO:
        draft.billingAddress = {
          unit: '',
          streetName: '',
          aptUnit: '',
          city: '',
          postalCode: '',
          province: '',
          country: '',
        };
        draft.firstName = null;
        draft.lastName = null;
        draft.phone = null;
        draft.email = null;
        draft.loading = false;
        draft.userError = null;
        break;
      default:
        return state;
    }
  });

export { userReducer, initialState };
