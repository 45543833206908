import produce from 'immer';
import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  TOGGLE_EDIT,
  SEND_INVOICE,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_ERROR,
  CLEAR_SEND_INVOICE,
} from '../constants/invoice';

const initialState = {
  invoice: null,
  error: null,
  loading: false,
  loadingUpdate: false,
  updateSuccess: false,
  updateError: null,
  loadingSend: false,
  sendInvoiceSuccess: false,
};

const invoiceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_INVOICE:
        draft.loading = true;
        draft.invoice = null;
        draft.error = null;
        break;
      case GET_INVOICE_SUCCESS:
        draft.invoice = action.payload;
        draft.loading = false;
        break;
      case GET_INVOICE_ERROR:
        draft.error = action.payload;
        draft.loading = false;
        break;
      case UPDATE_INVOICE:
        draft.updateError = null;
        draft.loadingUpdate = true;
        draft.updateSuccess = false;
        break;
      case UPDATE_INVOICE_SUCCESS:
        draft.invoice = action.payload;
        draft.loadingUpdate = false;
        draft.updateSuccess = true;
        break;
      case UPDATE_INVOICE_ERROR:
        draft.updateError = action.payload;
        draft.loadingUpdate = false;
        break;
      case SEND_INVOICE:
        draft.sendInvoiceSuccess = false;
        draft.error = null;
        draft.loadingSend = true;
        break;
      case SEND_INVOICE_SUCCESS:
        draft.sendInvoiceSuccess = true;
        draft.loadingSend = false;
        break;
      case SEND_INVOICE_ERROR:
        draft.sendInvoiceSuccess = false;
        draft.error = action.payload;
        draft.loadingSend = false;
        break;
      case TOGGLE_EDIT:
        draft.updateError = null;
        draft.updateSuccess = false;
        break;
      case CLEAR_SEND_INVOICE:
        draft.sendInvoiceSuccess = false;
        draft.error = null;
        draft.loadingSend = false;
        break;
      default:
        return state;
    }
  });

export { invoiceReducer, initialState };
