import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import { messages } from 'sharedlibs/lib/i18n';

import antdEn from 'antd/lib/locale-provider/en_US';
import antdFr from 'antd/lib/locale-provider/fr_FR';

export default function AppProvider({ children }) {
  const locale = useSelector(({ languageReducer }) => languageReducer.locale);
  const { changeThemes } = useSelector(
    ({ adminThemeReducer }) => adminThemeReducer
  );
  return (
    <ConfigProvider locale={locale === 'fr' ? antdFr : antdEn}>
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        <ThemeProvider theme={themes[changeThemes]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
