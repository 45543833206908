import enMesssages from './translations/en.json';
import frMesssages from './translations/fr.json';

const appLocales = ['en', 'fr'];
const messages = {
  en: enMesssages,
  fr: frMesssages,
};

export { messages, appLocales };
