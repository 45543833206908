import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@iso/components/uielements/button';
import IntlMessages from '@iso/components/utility/intlMessages';

import SignInStyleWrapper from './SignIn.styles';

export default function SignIn() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  function handleLogin(e) {
    e.preventDefault();
    loginWithRedirect();
  }

  return (
    <SignInStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <Link to="/">
              <img src="/movemate_logo_filled.svg" alt="movemate" />
              <IntlMessages id="page.signInTitle" />
            </Link>
          </div>
          <div className="isoSignInForm">
            <div className="isoCenterComponent isoHelperWrapper">
              <Button type="primary" onClick={handleLogin}>
                <IntlMessages id="page.signInButton" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SignInStyleWrapper>
  );
}
