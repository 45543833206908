const DOCUMENT = 'CITY_';

export const DELETE_CITY = DOCUMENT + 'DELETE_CITY';
export const DELETE_CITY_ERROR = DOCUMENT + 'DELETE_CITY_ERROR';
export const DELETE_CITY_SUCCESS = DOCUMENT + 'DELETE_CITY_SUCCESS';

export const UPDATE_CITY = DOCUMENT + 'UPDATE_CITY';
export const UPDATE_CITY_ERROR = DOCUMENT + 'UPDATE_CITY_ERROR';
export const UPDATE_CITY_SUCCESS = DOCUMENT + 'UPDATE_CITY_SUCCESS';
export const SET_LOADING = DOCUMENT + 'SET_LOADING';
export const CLEAR_CITY = DOCUMENT + 'CLEAR_CITY';

export const CREATE_CITY = DOCUMENT + 'CREATE_CITY';
export const CREATE_CITY_ERROR = DOCUMENT + 'CREATE_CITY_ERROR';
export const CREATE_CITY_SUCCESS = DOCUMENT + 'CREATE_CITY_SUCCESS';

export const GET_CITY = DOCUMENT + 'GET_CITY';
export const GET_CITY_BY_ID = DOCUMENT + 'GET_CITY_BY_ID';
export const GET_CITY_BY_ID_ERROR = DOCUMENT + 'GET_CITY_BY_ID_ERROR';
export const GET_CITY_BY_ID_SUCCESS = DOCUMENT + 'GET_CITY_BY_ID_SUCCESS';
export const GET_CITY_SUCCESS = DOCUMENT + 'GET_CITY_SUCCESS';
export const GET_CITY_ERROR = DOCUMENT + 'GET_CITY_ERROR';
