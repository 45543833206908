import produce from 'immer';
import * as partnerConstants from '../constants/b2cPartner';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  deleted: false,
  partners: [],
  error: null,
  errorSave: null,
  viewedPartner: null,
};

const adminB2CPartnerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case partnerConstants.CREATE_PARTNER:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case partnerConstants.CREATE_PARTNER_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedPartner = action.payload;
        break;
      case partnerConstants.CREATE_PARTNER_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case partnerConstants.GET_PARTNER_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case partnerConstants.GET_PARTNER_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedPartner = action.data;
        break;
      case partnerConstants.GET_PARTNER_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case partnerConstants.GET_PARTNER:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case partnerConstants.GET_PARTNER_SUCCESS:
        draft.isLoading = false;
        draft.partners = action.partners;
        break;
      case partnerConstants.GET_PARTNER_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        draft.partners = [];
        break;
      case partnerConstants.UPDATE_PARTNER:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case partnerConstants.UPDATE_PARTNER_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedPartner = action.payload;
        break;
      case partnerConstants.UPDATE_PARTNER_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;
      case partnerConstants.DELETE_PARTNER:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case partnerConstants.DELETE_PARTNER_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.deleted = true;
        break;
      case partnerConstants.DELETE_PARTNER_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      case partnerConstants.CLEAR_PARTNER:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.deleted = false;
        draft.viewedPartner = null;
        break;
      default:
        return state;
    }
  });

export { adminB2CPartnerReducer, initialState };
