export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const SET_ESTIMATE = 'SET_ESTIMATE';
export const SET_QUOTE = 'SET_QUOTE';
export const ERROR_QUOTE = 'ERROR_QUOTE';
export const START_QUOTE = 'START_QUOTE';
export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const CLEAR_BOOKING_INFO = 'CLEAR_BOOKING_INFO';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_ERROR = 'GET_BOOKINGS_ERROR';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const CANCEL_BOOKING_ERROR = 'CANCEL_BOOKING_ERROR';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const CREATE_BOOKINGS_ERROR = 'CREATE_BOOKINGS_ERROR';
export const CLEAN_BOOKINGS_ERROR = 'CLEAN_BOOKINGS_ERROR';
export const LEAD_SUCCESS = 'LEAD_SUCCESS';
export const LEAD_LOADING = 'LEAD_LOADING';
export const LEAD_ERROR = 'LEAD_ERROR';
