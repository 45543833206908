import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';

import { adminStore } from 'sharedlibs/lib';

import Routes from './router';
import AppProvider from './AppProvider';
import AuthProvider from './AuthProvider';

const App = () => (
  <AuthProvider>
    <Provider store={adminStore}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  </AuthProvider>
);

export default App;
