import bookingState from './bookingState';
import mapboxState from './mapboxState';
import { initialState as furnitureState } from '../reducers/furnitureReducer';
import { initialState as languageState } from '../reducers/languageReducer';
import { initialState as paymentState } from '../reducers/paymentReducer';
import { initialState as rateState } from '../reducers/rateReducer';
import { initialState as userState } from '../reducers/userReducer';
import { initialState as tipState } from '../reducers/tipReducer';
import { initialState as geoState } from '../reducers/geoReducer';
import { initialState as expireState } from '../reducers/expireAtReducer';

/**
 * the initial state of each of the persisted reducers.
 * if a reducer needs to be persisted, add to object.
 */
const persistedInitialState = {
  'bookingReducer': bookingState,
  'furnitureReducer': furnitureState,
  'languageReducer': languageState,
  'mapboxReducer': mapboxState,
  'paymentReducer': paymentState,
  'rateReducer': rateState,
  'userReducer': userState,
  'tipReducer': tipState,
  'geoReducer': geoState,
  'expireAtReducer': expireState,
};

export default persistedInitialState;
