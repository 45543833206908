import produce from 'immer';
import * as actions from '../constants/tip'

const initialState = {
    type: null,
    value: 0.00,
    isLoading: false,
    error: null,
}

const tipReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case actions.UPDATE_TIP:
                Object.entries(action.tipData).forEach(([key, value]) => {
                    draft[key] = value;
                });
                break;
            case actions.CLEAR_TIP:
                state = initialState;
                return state;
            case actions.GET_TIP:
                draft.isLoading = true;
                break;
            case actions.GET_TIP_SUCCESS:
                draft.isLoading = false;
                draft.type = 'GET';
                draft.value = action.tipData.value;
                break;
            case actions.TIP_ERROR:
                draft.isLoading = false;
                draft.error = action.error;
                break;
            default:
                return state;
        }
    })
}

export { tipReducer, initialState };