const DOCUMENT = 'CALENDAR_';

export const DELETE_CALENDAR = DOCUMENT + 'DELETE_CALENDAR';
export const DELETE_CALENDAR_ERROR = DOCUMENT + 'DELETE_CALENDAR_ERROR';
export const DELETE_CALENDAR_SUCCESS = DOCUMENT + 'DELETE_CALENDAR_SUCCESS';

export const UPDATE_CALENDAR = DOCUMENT + 'UPDATE_CALENDAR';
export const UPDATE_CALENDAR_ERROR = DOCUMENT + 'UPDATE_CALENDAR_ERROR';
export const UPDATE_CALENDAR_SUCCESS = DOCUMENT + 'UPDATE_CALENDAR_SUCCESS';
export const SET_LOADING = DOCUMENT + 'SET_LOADING';
export const CLEAR_CALENDAR = DOCUMENT + 'CLEAR_CALENDAR';

export const CREATE_CALENDAR = DOCUMENT + 'CREATE_CALENDAR';
export const CREATE_CALENDAR_ERROR = DOCUMENT + 'CREATE_CALENDAR_ERROR';
export const CREATE_CALENDAR_SUCCESS = DOCUMENT + 'CREATE_CALENDAR_SUCCESS';

export const GET_CALENDAR = DOCUMENT + 'GET_CALENDAR';
export const GET_CALENDAR_BY_ID = DOCUMENT + 'GET_CALENDAR_BY_ID';
export const GET_CALENDAR_BY_ID_ERROR = DOCUMENT + 'GET_CALENDAR_BY_ID_ERROR';
export const GET_CALENDAR_BY_ID_SUCCESS =
  DOCUMENT + 'GET_CALENDAR_BY_ID_SUCCESS';
export const GET_CALENDAR_SUCCESS = DOCUMENT + 'GET_CALENDAR_SUCCESS';
export const GET_CALENDAR_ERROR = DOCUMENT + 'GET_CALENDAR_ERROR';
