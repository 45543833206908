const DOCUMENT = 'B2C_PARTNER_';

export const DELETE_PARTNER = DOCUMENT + 'DELETE_PARTNER';
export const DELETE_PARTNER_ERROR = DOCUMENT + 'DELETE_PARTNER_ERROR';
export const DELETE_PARTNER_SUCCESS = DOCUMENT + 'DELETE_PARTNER_SUCCESS';

export const UPDATE_PARTNER = DOCUMENT + 'UPDATE_PARTNER';
export const UPDATE_PARTNER_ERROR = DOCUMENT + 'UPDATE_PARTNER_ERROR';
export const UPDATE_PARTNER_SUCCESS = DOCUMENT + 'UPDATE_PARTNER_SUCCESS';
export const SET_LOADING = DOCUMENT + 'SET_LOADING';
export const CLEAR_PARTNER = DOCUMENT + 'CLEAR_PARTNER';

export const CREATE_PARTNER = DOCUMENT + 'CREATE_PARTNER';
export const CREATE_PARTNER_ERROR = DOCUMENT + 'CREATE_PARTNER_ERROR';
export const CREATE_PARTNER_SUCCESS = DOCUMENT + 'CREATE_PARTNER_SUCCESS';

export const GET_PARTNER = DOCUMENT + 'GET_PARTNER';
export const GET_PARTNER_BY_ID = DOCUMENT + 'GET_PARTNER_BY_ID';
export const GET_PARTNER_BY_ID_ERROR = DOCUMENT + 'GET_PARTNER_BY_ID_ERROR';
export const GET_PARTNER_BY_ID_SUCCESS = DOCUMENT + 'GET_PARTNER_BY_ID_SUCCESS';
export const GET_PARTNER_SUCCESS = DOCUMENT + 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_ERROR = DOCUMENT + 'GET_PARTNER_ERROR';
