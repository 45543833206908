import { createTransform } from 'redux-persist';
import persistedInitialState from '../states/persistedInitialState';

/**
 * redux-persist expireTransform
 * "middleware" for persistence. Used to check if the persistence has expired.
 * this gets called for every reducer in the whitelist.
 */
const expireTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, key, rawState) => {
    if (!outboundState) return outboundState;

    // get expireAt value from the expire reducer
    const expireValue = JSON.parse(rawState['expireAtReducer']);
    const currentTime = new Date().getTime();
    if (!expireValue['expireAt'] || currentTime > expireValue['expireAt']) {
      return persistedInitialState[key];
    }
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: [...Object.keys(persistedInitialState)] }
);

export default expireTransform;
