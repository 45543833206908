import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SignInPage from '@iso/containers/Pages/SignIn/SignIn';

const PrivateRoute = ({ component, ...args }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return 'Loading..';
  }

  const getAuthPage = () => {
    if (isAuthenticated) {
      return component;
    }
    return SignInPage;
  };

  return <Route component={getAuthPage()} {...args} />;
};

export default PrivateRoute;
