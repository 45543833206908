import produce from 'immer';
import {
  TEMP_UPDATE_BOOKING,
  TEMP_SET_ESTIMATE,
  TEMP_SET_DISCOUNT_CODE,
  TEMP_CLEAR_BOOKING_INFO,
  TEMP_GET_BOOKING,
  TEMP_GET_BOOKING_ERROR,
  TEMP_GET_BOOKING_SUCCESS,
  TEMP_EDIT_BOOKING,
  TEMP_EDIT_BOOKING_ERROR,
  TEMP_EDIT_BOOKING_SUCCESS,
  UPDATE_TEMP_EDIT_BOOKING_SUCCESS,
  TEMP_GET_VISITOR_BOOKING,
  TEMP_GET_VISITOR_BOOKING_ERROR,
  TEMP_GET_VISITOR_BOOKING_SUCCESS,
  TEMP_GET_QUOTE,
  TEMP_GET_QUOTE_ERROR,
  TEMP_GET_QUOTE_SUCCESS,
} from '../constants/tempBooking';
import bookingState from '../states/bookingState';

const initialState = {
  ...bookingState,
  editBookingLoading: false,
  editBookingError: null,
  editBookingSuccess: false,
  getQuoteLoading: false,
  getQuoteError: null,
  getQuoteSuccess: false,
  bookingData: null,
  cipher: null,
  loading: true,
  error: null,
};

const tempBookingReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TEMP_UPDATE_BOOKING:
        Object.entries(action.bookingData).forEach(([key, value]) => {
          draft[key] = value;
        });
        break;
      case TEMP_SET_ESTIMATE:
        draft.estimate = action.estimate;
        draft.drivingDuration = action.estimate.drivingDuration;
        break;
      case TEMP_SET_DISCOUNT_CODE:
        draft.discountCode = action.discountCode;
        break;
      case TEMP_CLEAR_BOOKING_INFO:
        state = initialState;
        return state;
      case TEMP_GET_BOOKING:
        state = { ...initialState };
        return state;
      case TEMP_GET_BOOKING_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case TEMP_GET_BOOKING_SUCCESS:
        state = {
          ...state,
          ...action.payload.booking,
          cipher: action.payload.cipher,
          loading: false,
        };
        return state;
      case TEMP_EDIT_BOOKING:
        draft.editBookingLoading = true;
        draft.editBookingError = null;
        draft.editBookingSuccess = false;
        break;
      case TEMP_EDIT_BOOKING_ERROR:
        draft.editBookingLoading = false;
        draft.editBookingError = action.payload;
        break;
      case TEMP_EDIT_BOOKING_SUCCESS:
        draft.editBookingLoading = false;
        draft.editBookingSuccess = true;
        break;
      case UPDATE_TEMP_EDIT_BOOKING_SUCCESS:
        draft.editBookingSuccess = false;
        break;
      case TEMP_GET_VISITOR_BOOKING:
        draft = { ...initialState };
        return state;
      case TEMP_GET_VISITOR_BOOKING_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case TEMP_GET_VISITOR_BOOKING_SUCCESS:
        state = {
          ...state,
          bookingData: action.payload.booking,
          cipher: action.payload.cipher,
          loading: false,
        };
        return state;
      case TEMP_GET_QUOTE:
        draft.getQuoteLoading = true;
        draft.getQuoteError = null;
        draft.getQuoteSuccess = false;
        break;
      case TEMP_GET_QUOTE_ERROR:
        draft.getQuoteLoading = false;
        draft.getQuoteError = action.payload;
        break;
      case TEMP_GET_QUOTE_SUCCESS:
        draft.getQuoteLoading = false;
        draft.getQuoteSuccess = true;
        break;
      default:
        return state;
    }
  });
};

export { tempBookingReducer, initialState };
