import produce from 'immer';

const currentDate = new Date();
const initialState = {
  expireAt: new Date(currentDate).setDate(currentDate.getDate() + 28),
};

const expireAtReducer = (state = initialState, action) =>
  produce(state, () => {
    switch (action.type) {
      default:
        return state;
    }
  });

export { expireAtReducer, initialState };