import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

// configuration object for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'adminAppReducer',
    'adminBookingReducer',
    'adminCityReducer',
    'adminCalendarReducer',
    'bookingReducer',
    'mapboxReducer',
    'userReducer',
    'invoiceReducer',
    'opsReducer',
    'paymentReducer',
    'furnitureReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const adminStore = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  composeWithDevTools(applyMiddleware(thunk))
);

const persistorAdmin = persistStore(adminStore); // used to create the persisted store, persistor will be used in the next step

export { adminStore, persistorAdmin };
