import produce from 'immer';
import {
  GET_ROUTE_DATA,
  GET_ROUTE_DATA_SUCCESS,
  GET_ROUTE_DATA_ERROR,
  CLEAR_MAPBOX_INFO,
} from '../constants/mapbox';
import mapboxState from '../states/mapboxState';

const initialState = mapboxState;

const mapboxReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ROUTE_DATA:
        draft.loading = true;
        break;
      case GET_ROUTE_DATA_SUCCESS:
        draft.loading = false;
        draft.route = action.payload.route;
        draft.duration = action.payload.duration;
        draft.distance = action.payload.distance;
        draft.pickup = action.payload.pickup;
        draft.dropoff = action.payload.dropoff;
        break;
      case GET_ROUTE_DATA_ERROR:
        draft.loading = false;
        break;
      case CLEAR_MAPBOX_INFO:
        draft.loading = false;
        draft.route = '';
        draft.duration = 0;
        draft.distance = 0;
        draft.error = '';
        draft.pickup = {
          unit: '',
          street: '',
          aptUnit: '',
          postalCode: '',
          city: '',
          province: '',
          country: '',
        };
        draft.dropoff = {
          unit: '',
          street: '',
          aptUnit: '',
          postalCode: '',
          city: '',
          province: '',
          country: '',
        };
        break;
      default:
        return state;
    }
  });

export { mapboxReducer };
