import produce from 'immer';
import { SET_QUERY_PARAMS, CLEAR_QUERY_INFO } from '../constants/query';

const initialState = {
  discount: null,
  pickupFullAddress: null,
  pickupApt: null,
  pickupFloor: null,
  pickupParking: null,
  pickupAccessibility: null,
  dropoffFullAddress: null,
  dropoffApt: null,
  dropoffParking: null,
  dropoffFloor: null,
  dropoffAccessibility: null,
  skipLeadForm: false,
};

const queryReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_QUERY_PARAMS:
        Object.entries(action.payload).forEach(([key, val]) => {
          draft[key] = val;
        });
        break;
      case CLEAR_QUERY_INFO:
        state = initialState;
        return state;
      default:
        return state;
    }
  });
};

export { queryReducer, initialState };
