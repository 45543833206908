import produce from 'immer';
import * as mateConstants from '../constants/mate';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  mates: [],
  workers: [],
  total: 0,
  error: null,
  errorSave: null,
  viewedMate: null,
};

const adminMateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case mateConstants.CREATE_MATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case mateConstants.CREATE_MATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case mateConstants.CREATE_MATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case mateConstants.GET_MATE_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case mateConstants.GET_MATE_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedMate = action.data;
        break;
      case mateConstants.GET_MATE_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case mateConstants.GET_ONFLEET_MATES:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case mateConstants.GET_ONFLEET_MATES_SUCCESS:
        draft.isLoading = false;
        draft.workers = action.workers;
        break;
      case mateConstants.GET_ONFLEET_MATES_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case mateConstants.GET_MATE:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case mateConstants.GET_MATE_SUCCESS:
        draft.isLoading = false;
        draft.mates = action.mates;
        draft.total = action.total;
        break;
      case mateConstants.GET_MATE_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case mateConstants.UPDATE_MATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case mateConstants.UPDATE_MATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case mateConstants.UPDATE_MATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      case mateConstants.DELETE_MATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case mateConstants.DELETE_MATE_SUCCESS:
        draft.viewedMate = { ...action.data, deleted: true };
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case mateConstants.DELETE_MATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      case mateConstants.CLEAR_MATE:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.viewedMate = {
          mateID: '',
          name: '',
          address: '',
          phone: '',
          email: '',
          neq: '',
          tps: '',
          tvq: '',
        };
        break;
      default:
        return state;
    }
  });

export { adminMateReducer, initialState };
