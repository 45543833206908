import produce from 'immer';
import * as calendarConstants from '../constants/calendar';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  deleted: false,
  calendars: [],
  googleServiceCalendars: [],
  count: 0,
  error: null,
  errorSave: null,
  viewedCalendar: null,
};

const adminCalendarReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case calendarConstants.CREATE_CALENDAR:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case calendarConstants.CREATE_CALENDAR_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedCalendar = action.payload;
        break;
      case calendarConstants.CREATE_CALENDAR_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case calendarConstants.GET_CALENDAR_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case calendarConstants.GET_CALENDAR_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedCalendar = action.data;
        break;
      case calendarConstants.GET_CALENDAR_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case calendarConstants.GET_CALENDAR:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case calendarConstants.GET_CALENDAR_SUCCESS:
        draft.isLoading = false;
        draft.calendars = action.calendars;
        draft.googleServiceCalendars = action.googleServiceCalendars;
        draft.count = action.count;
        break;
      case calendarConstants.GET_CALENDAR_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        draft.count = 0;
        draft.calendars = [];
        draft.googleServiceCalendars = [];
        break;
      case calendarConstants.UPDATE_CALENDAR:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case calendarConstants.UPDATE_CALENDAR_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedCalendar = action.payload;
        break;
      case calendarConstants.UPDATE_CALENDAR_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;
      case calendarConstants.DELETE_CALENDAR:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case calendarConstants.DELETE_CALENDAR_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.deleted = true;
        break;
      case calendarConstants.DELETE_CALENDAR_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      case calendarConstants.CLEAR_CALENDAR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.deleted = false;
        draft.viewedCalendar = null;
        break;
      default:
        return state;
    }
  });

export { adminCalendarReducer, initialState };
