import produce from 'immer';
import * as cityConstants from '../constants/city';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  deleted: false,
  cities: [],
  count: 0,
  error: null,
  errorSave: null,
  viewedCity: null,
};

const adminCityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case cityConstants.CREATE_CITY:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        break;
      case cityConstants.CREATE_CITY_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedCity = action.payload;
        break;
      case cityConstants.CREATE_CITY_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.error = action.payload;
        break;

      case cityConstants.GET_CITY_BY_ID:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case cityConstants.GET_CITY_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedCity = action.data;
        break;
      case cityConstants.GET_CITY_BY_ID_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case cityConstants.GET_CITY:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case cityConstants.GET_CITY_SUCCESS:
        draft.isLoading = false;
        draft.cities = action.cities;
        draft.count = action.count;
        break;
      case cityConstants.GET_CITY_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        draft.count = 0
        draft.cities = [];
        break;
      case cityConstants.UPDATE_CITY:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case cityConstants.UPDATE_CITY_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.viewedCity = action.payload;
        break;
      case cityConstants.UPDATE_CITY_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;
      case cityConstants.DELETE_CITY:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case cityConstants.DELETE_CITY_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        draft.deleted = true;
        break;
      case cityConstants.DELETE_CITY_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;

      case cityConstants.CLEAR_CITY:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.deleted = false;
        draft.viewedCity = null;
        break;
      default:
        return state;
    }
  });

export { adminCityReducer, initialState };
