import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import ReactBreakpoints from 'react-breakpoints'
import isDev from 'sharedlibs/lib/utils/isDev';

const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscapeSmall: 924,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

ReactDOM.render(
  <ReactBreakpoints breakpoints={breakpoints}>
    <App />
  </ReactBreakpoints>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const isFirefox = typeof InstallTrigger !== 'undefined';
if (isFirefox && isDev()) {
  // Bypass a bug making React app to fail to load on localhost in Firefox because
  // cookies are blocked.
  // Prevents error "Uncaught (in promise) DOMException: The operation is insecure."
  // or "Unhandled Rejection (SecurityError): The operation is insecure."
  serviceWorker.register();
}
else {
  serviceWorker.unregister();
}
