import { combineReducers } from 'redux';

import { adminAppReducer } from './adminAppReducer';
import { adminBookingReducer } from './adminBookingReducer';
import { adminB2BTASKReducer as adminB2BBookingReducer } from './adminB2BBookingReducer';
import { adminDiscountReducer } from './adminDiscountReducer';
import { adminThemeReducer } from './adminThemeReducer';
import { adminMessageReducer } from './adminMessageReducer';
import { adminTaskReducer } from './adminTaskReducer';
import { bookingReducer } from './bookingReducer';
import { furnitureReducer } from './furnitureReducer';
import { invoiceReducer } from './invoiceReducer';
import { languageReducer } from './languageReducer';
import { mapboxReducer } from './mapboxReducer';
import { opsReducer } from './opsReducer';
import { paymentReducer } from './paymentReducer';
import { rateReducer } from './rateReducer';
import { adminRateReducer } from './adminRateReducer';
import { adminB2BPartnerReducer } from './adminB2BPartnerReducer';
import { adminMateReducer } from './adminMateReducer';
import { userReducer } from './userReducer';
import { tempBookingReducer } from './tempBookingReducer';
import { tempMapboxReducer } from './tempMapboxReducer';
import { tipReducer } from './tipReducer';
import { queryReducer } from './queryReducer';
import { geoReducer } from './geoReducer';
import { partnerInvoiceReducer } from './partnerInvoiceReducer';
import { expireAtReducer } from './expireAtReducer';
import { adminB2CPartnerReducer } from './adminB2CPartnerReducer';
import { adminCityReducer } from './adminCityReducer.js';
import { adminCalendarReducer } from './adminCalendarReducer.js';

const rootReducer = combineReducers({
  adminAppReducer,
  adminBookingReducer,
  adminB2BBookingReducer,
  adminB2CPartnerReducer,
  adminCityReducer,
  adminCalendarReducer,
  adminDiscountReducer,
  adminRateReducer,
  adminMessageReducer,
  adminB2BPartnerReducer,
  adminMateReducer,
  adminThemeReducer,
  adminTaskReducer,
  bookingReducer,
  furnitureReducer,
  invoiceReducer,
  languageReducer,
  mapboxReducer,
  opsReducer,
  paymentReducer,
  rateReducer,
  userReducer,
  tempBookingReducer,
  tempMapboxReducer,
  tipReducer,
  queryReducer,
  geoReducer,
  partnerInvoiceReducer,
  expireAtReducer,
});

export default rootReducer;
