const DOCUMENT = 'MATE_';

export const UPDATE_MATE = DOCUMENT + 'UPDATE_MATE';
export const UPDATE_MATE_ERROR = DOCUMENT + 'UPDATE_MATE_ERROR';
export const UPDATE_MATE_SUCCESS = DOCUMENT + 'UPDATE_MATE_SUCCESS';
export const SET_LOADING = DOCUMENT + 'SET_LOADING';
export const CLEAR_MATE = DOCUMENT + 'CLEAR_MATE';

export const CREATE_MATE = DOCUMENT + 'CREATE_MATE';
export const CREATE_MATE_ERROR = DOCUMENT + 'CREATE_MATE_ERROR';
export const CREATE_MATE_SUCCESS = DOCUMENT + 'CREATE_MATE_SUCCESS';

export const GET_MATE = DOCUMENT + 'GET_MATE';
export const GET_MATE_BY_ID = DOCUMENT + 'GET_MATE_BY_ID';
export const GET_MATE_BY_ID_ERROR = DOCUMENT + 'GET_MATE_BY_ID_ERROR';
export const GET_MATE_BY_ID_SUCCESS = DOCUMENT + 'GET_MATE_BY_ID_SUCCESS';
export const GET_MATE_SUCCESS = DOCUMENT + 'GET_MATE_SUCCESS';
export const GET_MATE_ERROR = DOCUMENT + 'GET_MATE_ERROR';

export const GET_ONFLEET_MATES = DOCUMENT + 'GET_ONFLEET_MATES';
export const GET_ONFLEET_MATES_SUCCESS = DOCUMENT + 'GET_ONFLEET_MATES_SUCCESS';
export const GET_ONFLEET_MATES_ERROR = DOCUMENT + 'GET_ONFLEET_MATES_ERROR';

export const DELETE_MATE = DOCUMENT + 'DELETE_MATE';
export const DELETE_MATE_ERROR = DOCUMENT + 'DELETE_MATE_ERROR';
export const DELETE_MATE_SUCCESS = DOCUMENT + 'DELETE_MATE_SUCCESS';
